import React, { useState, useMemo, useEffect, useRef } from "react";
import { IonContent, IonPage, IonSpinner, IonButton, IonGrid, IonCol, IonRow, IonIcon } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useStoriesQuery, IStory } from "../types/graphql";
import { useUser } from "../providers/user-provider";
import useAnalytics from "../hooks/use-analytics";
import StoryItemSkeleton from "../components/story-item-skeleton";
import StoryItemContainer from "../components/story-item-container";
import { SECTIONS } from "../utils/sections";

interface ISectionPageProps
    extends RouteComponentProps<{
        section: string;
    }> { }


const SectionPage: React.FC<ISectionPageProps> = ({ match }) => {
    const contentRef = useRef(null);
    const { logCurrentScreen } = useAnalytics();
    useEffect(() => {
        logCurrentScreen('SectionPage');
    }, [logCurrentScreen]);
    useEffect(() => {
        setTimeout(() => {
            // @ts-ignore
            contentRef.current?.scrollToTop();
        }, 200);
    }, [match.params.section])
    const section = SECTIONS.find((section) => section.id === match.params.section);
    const { sources } = useUser();
    const { data, loading, fetchMore } = useStoriesQuery({
        variables: {
            sources_in: [...sources],
            section: match.params.section
        },
        skip: !sources?.length,
    });
    const stories = data?.stories;
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (stories?.length ? stories[stories.length - 1]?.date : null),
        [stories]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!stories?.length && stories.length > 19,
        [stories]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonContent ref={contentRef} scrollEvents={true}>
                <div className="content-md ion-margin">
                    <IonGrid style={{ maxWidth: 420, margin: "auto" }}>
                        <IonRow>
                            <IonCol size="12" className="ion-text-center">
                                <IonIcon style={{ fontSize: 40 }} icon={section?.icon} />
                            </IonCol>
                            <IonCol size="12" className="ion-text-center">
                                <h1 style={{ margin: "0 auto 16px" }}>{section?.title}</h1>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                    >
                        {loading && (
                            <>
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                            </>
                        )}
                        {data?.stories?.map((story) => (
                            <StoryItemContainer key={story?._id} story={story as IStory} />
                        ))}
                    </div>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {lastStoryDate && areMoreStories && !pagination.completed && (
                            <div style={{ textAlign: "center", marginBottom: 64 }}>
                                {pagination.loading ? (
                                    <IonSpinner style={{ height: 40 }} />
                                ) : (
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => onFetchMore()}
                                        >
                                            Más Historias
                                        </IonButton>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SectionPage;
