import React from "react";
import { IonItem } from "@ionic/react";
import { IStory } from "../types/graphql";
import StoryItemContainer from "./story-item-container";

interface IHomeHeadlinesProps {
    headlines?: IStory[];
}

const HomeHeadlines: React.FC<IHomeHeadlinesProps> = ({ headlines }) => {
    if (!headlines?.length) {
        return null;
    }
    return (
        <>
            <IonItem>
                <h3>Destacadas</h3>
            </IonItem>
            <div>
                {headlines.map((story) => (
                    <StoryItemContainer key={story._id} story={story} thumbnail={true} />
                ))}
            </div>
        </>
    );
};

export default HomeHeadlines;
