import React, { useState, useMemo, useEffect } from "react";
import {
    IonContent,
    IonPage,
    IonSpinner,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonSkeletonText,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useStoriesQuery, useStoryQuery, IStory } from "../types/graphql";
import StoryItemSkeleton from "../components/story-item-skeleton";
import StoryItemContainer from "../components/story-item-container";
import useAnalytics from "../hooks/use-analytics";
import { useUser } from "../providers/user-provider";

interface IStoriesRelatedPageProps
    extends RouteComponentProps<{
        story: string;
    }> { }

const StoriesRelatedPage: React.FC<IStoriesRelatedPageProps> = ({ match }) => {
    const { logCurrentScreen } = useAnalytics();
    const { sources } = useUser();
    useEffect(() => {
        logCurrentScreen('StoriesRelatedPage');
    }, [logCurrentScreen]);
    const { data, loading, fetchMore } = useStoriesQuery({
        variables: {
            sources_in: [...sources],
            related: match.params.story,
        },
    });
    const { data: dataStory, loading: loadingStory } = useStoryQuery({
        variables: {
            id: match.params.story
        },
    });
    const stories = [...data?.stories as IStory[] || []];
    stories.sort((a: IStory, b: IStory) => new Date(b.date).getTime() - new Date(a.date).getTime())
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (stories?.length ? stories[stories.length - 1]?.date : null),
        [stories]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!stories?.length && stories.length > 19,
        [stories]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    <IonGrid style={{ maxWidth: 420, margin: "0 auto 32px" }}>
                        <IonRow>
                            <IonCol size="12" className="ion-text-center">
                                {loadingStory ? (
                                    <div
                                        style={{
                                            margin: "auto",
                                            borderRadius: 10,
                                            backgroundColor: "#efefef",
                                            width: 256,
                                            height: 160,
                                        }}
                                    />
                                ) : (
                                        <img
                                            style={{ width: 256, borderRadius: 10 }}
                                            src={dataStory?.story?.image?.url || undefined}
                                            alt={dataStory?.story?.title || undefined}
                                        />
                                    )}
                            </IonCol>
                            <IonCol size="12" className="ion-text-center">
                                {loadingStory ? (
                                    <>
                                        <IonSkeletonText
                                            animated
                                            style={{ margin: "0 auto 10px", height: 24, width: 128 }}
                                        />
                                        <IonSkeletonText
                                            animated
                                            style={{ margin: "0 auto 10px", height: 24, width: 320 }}
                                        />
                                        <IonSkeletonText
                                            animated
                                            style={{ margin: "0 auto 10px", height: 24, width: 320 }}
                                        />
                                    </>
                                ) : (
                                        <>
                                            <p>{dataStory?.story?.source?.name}</p>
                                            <div className="link" onClick={() => window.open(dataStory?.story?.url as string, "_blank")}>
                                                <h3>{dataStory?.story?.title}</h3>
                                            </div>
                                        </>
                                    )}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                    >
                        {loading && (
                            <>
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                            </>
                        )}
                        {stories?.map((story) => (
                            <StoryItemContainer key={story?._id} story={story as IStory} />
                        ))}
                    </div>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {lastStoryDate && areMoreStories && !pagination.completed && (
                            <div style={{ textAlign: "center", marginBottom: 64 }}>
                                {pagination.loading ? (
                                    <IonSpinner style={{ height: 40 }} />
                                ) : (
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => onFetchMore()}
                                        >
                                            Más Historias
                                        </IonButton>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage >
    );
};

export default StoriesRelatedPage;
