import React, { useState } from "react";
import { IonPopover, IonList, IonItem, IonIcon } from "@ionic/react";
import { IStory, ISource } from "../types/graphql";
import { useUser } from "../providers/user-provider";
import StoryItemThumbnail from "./story-item-thumbnail";
import StoryItem from "./story-item";
import { useHistory } from "react-router";
import {
    trashBinOutline,
    bookmarkOutline,
    openOutline,
    eyeOffOutline,
    shareSocialOutline,
    copyOutline,
} from "ionicons/icons";
import useAnalytics from "../hooks/use-analytics";

interface IStoryItemContainerPros {
    story: IStory | null;
    thumbnail?: boolean;
}

const StoryItemContainer: React.FC<IStoryItemContainerPros> = ({ story, thumbnail }) => {
    const { logStoryClick, logStorySave, logStoryShare, logSourceClick, logSourceHide } = useAnalytics();
    const { push } = useHistory();
    const { favorites, addFavorite, delFavorite, delSource } = useUser();
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const onClick = () => {
        logStoryClick(story as IStory);
        window.open(story?.url as string, "_blank");
    }
    const onClickMenu = (e: any) => {
        e.stopPropagation();
        setShowMenu(e.nativeEvent);
    };
    const onClickSource = (e: any) => {
        e.stopPropagation();
        logSourceClick(story?.source as ISource);
        push(`/sources/${story?.source?._id}`);
    };
    return (
        <>
            {thumbnail ? (
                <StoryItemThumbnail story={story} onClick={onClick} onClickMenu={onClickMenu} onClickSource={onClickSource} />
            ) : (
                    <StoryItem story={story} onClick={onClick} onClickMenu={onClickMenu} onClickSource={onClickSource} />
                )}
            <IonPopover
                event={showMenu}
                isOpen={!!showMenu}
                onDidDismiss={(e) => setShowMenu(undefined)}
            >
                <IonList lines="none">
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            push(`/story/${story?._id}/related`);
                        }}
                    >
                        <IonIcon icon={copyOutline} slot="start" />
                        Historias Relacionadas
                    </IonItem>
                    {favorites.some((_story) => _story._id === story?._id) ? (
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                delFavorite(story);
                            }}
                        >
                            <IonIcon icon={trashBinOutline} slot="start" />
                            Eliminar de Mis Historias
                        </IonItem>
                    ) : (
                            <IonItem
                                button
                                detail={false}
                                onClick={() => {
                                    setShowMenu(undefined);
                                    logStorySave(story as IStory);
                                    addFavorite(story);
                                }}
                            >
                                <IonIcon icon={bookmarkOutline} slot="start" />
                            Agregar a Mis Historias
                            </IonItem>
                        )}
                    {(navigator as any)?.share && (
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                logStoryShare(story as IStory);
                                (navigator as any).share({
                                    title: story?.title as string,
                                    url: story?.url as string,
                                });
                            }}
                        >
                            <IonIcon icon={shareSocialOutline} slot="start" />
                            Compartir
                        </IonItem>
                    )}
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            logSourceClick(story?.source as ISource);
                            push(`/sources/${story?.source?._id}`);
                        }}
                    >
                        <IonIcon icon={openOutline} slot="start" />
                        Historias de {story?.source?.name}
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            logSourceHide(story?.source as ISource);
                            delSource(story?.source?._id);
                        }}
                    >
                        <IonIcon icon={eyeOffOutline} slot="start" />
                        Ocultar historias de {story?.source?.name}
                    </IonItem>
                </IonList>
            </IonPopover>
        </>
    );
};

export default StoryItemContainer;
