import React, { useState, useEffect, useMemo } from "react";
import { IonContent, IonPage, IonSearchbar, IonItem, IonSpinner, IonButton } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import useStoriesQueryCustom from "../hooks/use-stories-query-custom";
import StoryItemContainer from "../components/story-item-container";
import useAnalytics from "../hooks/use-analytics";
import { IStory } from "../types/graphql";

const SearchPage: React.FC<RouteComponentProps> = ({ location }) => {
    const { logSearch, logCurrentScreen } = useAnalytics();
    const searchParams = new URLSearchParams(location.search);
    const searchParamsTerms = searchParams.get("term");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchText, setSearchText] = useState("");
    const { loading, data, fetchMore } = useStoriesQueryCustom({
        skip: !searchTerm,
        variables: { search: searchTerm },
    });
    useEffect(() => {
        logCurrentScreen('SearchPage');
    }, [logCurrentScreen])
    useEffect(() => {
        setSearchTerm(searchParamsTerms || "");
        setSearchText(searchParamsTerms || "");
    }, [searchParamsTerms]);
    useEffect(() => {
        if (searchTerm) {
            logSearch(searchTerm);
        }
    }, [logSearch, searchTerm])
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (data?.stories?.length ? data.stories[data.stories.length - 1]?.date : null),
        [data]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!data?.stories?.length && data.stories.length > 19,
        [data]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    <IonItem lines="none" className="ion-hide-lg-up">
                        <form
                            style={{ width: "100%" }}
                            onSubmit={(event) => {
                                event.preventDefault();
                                setSearchTerm(searchText);
                            }}
                        >
                            <IonSearchbar
                                placeholder="Buscar historias"
                                className="main-search"
                                debounce={0}
                                value={searchText}
                                onIonChange={(e) => setSearchText(e.detail.value!)}
                                onIonClear={() => setSearchTerm("")}
                            ></IonSearchbar>
                        </form>
                    </IonItem>
                    {!!searchTerm && (
                        <IonItem>
                            <h3>Resultados para "{searchTerm}"</h3>
                            {loading && <IonSpinner slot="end" />}
                        </IonItem>
                    )}
                    {!loading && searchTerm && !data?.stories?.length && (
                        <div className="ion-text-center" style={{ marginTop: 32 }}>
                            <img
                                style={{ maxWidth: 256 }}
                                alt="No se han encontrado noticias"
                                src="assets/images/search.png"
                            />
                            <h3>No se han encotrado noticias</h3>
                            <p>Modifique el texto e inténtelo de nuevo.</p>
                        </div>
                    )}
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                    >
                        {data?.stories?.map((story) => (
                            <StoryItemContainer key={story?._id} story={story as IStory} />
                        ))}
                    </div>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {lastStoryDate && areMoreStories && !pagination.completed && (
                            <div style={{ textAlign: "center", marginBottom: 64 }}>
                                {pagination.loading ? (
                                    <IonSpinner style={{ height: 40 }} />
                                ) : (
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => onFetchMore()}
                                        >
                                            Más Resultados
                                        </IonButton>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SearchPage;
