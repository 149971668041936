import React from "react";
import { IStory } from "../types/graphql";
import { IonItem, IonSpinner, IonButton } from "@ionic/react";
import StoryItemSkeleton from "./story-item-skeleton";
import StoryItemContainer from "./story-item-container";

interface IIHomeStoriesProps {
    stories?: IStory[];
    loading: boolean;
    pagination: {
        loading: boolean;
        completed: boolean;
    };
    lastStoryDate: Date | null;
    onFetchMore: any;
}

const HomeStories: React.FC<IIHomeStoriesProps> = ({
    loading,
    stories,
    pagination,
    lastStoryDate,
    onFetchMore,
}) => {
    const areMoreStories = !!stories?.length && stories.length > 19;
    return (
        <>
            {!!stories?.length && (
                <IonItem>
                    <h3>Recientes</h3>
                </IonItem>
            )}
            <div className="ion-padding-top">
                {loading && (
                    <>
                        <StoryItemSkeleton />
                        <StoryItemSkeleton />
                        <StoryItemSkeleton />
                    </>
                )}
                {stories?.map((story) => (
                    <StoryItemContainer key={story?._id} story={story} />
                ))}
            </div>
            <div className="ion-padding-top" style={{ display: "flex", justifyContent: "center" }}>
                {lastStoryDate && areMoreStories && !pagination.completed && (
                    <div style={{ textAlign: "center", marginBottom: 64 }}>
                        {pagination.loading ? (
                            <IonSpinner style={{ height: 40 }} />
                        ) : (
                            <IonButton color="dark" fill="outline" onClick={() => onFetchMore()}>
                                Más Noticias
                            </IonButton>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default HomeStories;
