import moment from "moment";
import React from "react";
import { IonButton, IonIcon, IonItem, IonThumbnail, IonLabel, IonText } from "@ionic/react";
import { IStory } from "../types/graphql";
import { ellipsisVertical } from "ionicons/icons";

interface IStoryItemThumbnailPros {
    story: IStory | null;
    onClick: any;
    onClickMenu?: any;
    onClickSource?: any;
}

const StoryItemThumbnail: React.FC<IStoryItemThumbnailPros> = ({ story, onClick, onClickMenu, onClickSource }) => {
    return (
        <IonItem lines="none">
            {story?.image && (
                <IonThumbnail slot="start">
                    <img src={story?.image?.url as string} alt={story?.title as string} />
                </IonThumbnail>
            )}
            <IonLabel className="ion-text-wrap">
                <div className="link" onClick={onClickSource}>
                    <p>{story?.source?.name}</p>
                </div>
                <IonText>
                    <div className="link" onClick={onClick}>
                        <h2 style={{ margin: "8px 0 8px 0" }}>{story?.title}</h2>
                    </div>
                </IonText>
                <IonText>
                    <p style={{ fontWeight: "bold" }}>{moment(story?.date).format("LLL")}</p>
                </IonText>
            </IonLabel>
            <IonButton slot="end" fill="clear" onClick={onClickMenu}>
                <IonIcon slot="icon-only" color="medium" icon={ellipsisVertical} />
            </IonButton>
        </IonItem>
    );
};

export default StoryItemThumbnail;
