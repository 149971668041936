import React, { useEffect } from "react";
import { IonContent, IonPage, IonItem } from "@ionic/react";
import { useSourcesQuery, ISource } from "../types/graphql";
import SourceItemSkeleton from "../components/source-item-skeleton";
import SourceItemContainer from "../components/source-item-container";
import useAnalytics from "../hooks/use-analytics";
import { useUser } from "../providers/user-provider";
import SourceFavorite from "../components/source-favorite";

const SourcesPage: React.FC = () => {
    const { logCurrentScreen } = useAnalytics();
    const { sourcesFavorites } = useUser();
    useEffect(() => {
        logCurrentScreen('SourcesPage');
    }, [logCurrentScreen])
    const { loading, data } = useSourcesQuery();
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    <IonItem>
                        <h3>Fuentes</h3>
                    </IonItem>
                    {loading && (
                        <>
                            <SourceItemSkeleton />
                            <SourceItemSkeleton />
                            <SourceItemSkeleton />
                        </>
                    )}
                    {data?.sources && sourcesFavorites
                        .map((sourceFavorite) => data.sources?.find((source) => source?._id === sourceFavorite))
                        .filter((sourceFavorite) => !!sourceFavorite)
                        .map((source) => <SourceFavorite key={source?._id} source={source as ISource} />)}
                    {data?.sources?.map((source) => (
                        <SourceItemContainer key={source?._id} source={source} />
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SourcesPage;
