import {
    colorPaletteOutline,
    cashOutline,
    heartOutline,
    hardwareChipOutline,
    footballOutline,
} from "ionicons/icons";

export const SECTIONS = [
    {
        id: 'culture',
        title: 'Cultura',
        icon: colorPaletteOutline
    },
    {
        id: 'sports',
        title: 'Deportes',
        icon: footballOutline
    },
    {
        id: 'economy',
        title: 'Economía',
        icon: cashOutline
    },
    {
        id: 'health',
        title: 'Salud',
        icon: heartOutline
    },
    {
        id: 'science',
        title: 'Ciencia y Tecnología',
        icon: hardwareChipOutline
    },
]