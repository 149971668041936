import React, { useState, useMemo, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useFeedQuery } from "../types/graphql";
import HomeBanner from "../components/home-banner";
import HomeHeadlines from "../components/home-headlines";
import HomeStories from "../components/home-stories";
import HomeCoverages from "../components/home-coverages";
import { useUser } from "../providers/user-provider";
import useAnalytics from "../hooks/use-analytics";

const HomePage: React.FC = () => {
    const { logCurrentScreen } = useAnalytics();
    const { sources, banner, headlines, coverages } = useUser();
    const { data, loading, fetchMore } = useFeedQuery({
        variables: { sources_in: [...sources] },
        skip: !sources?.length,
    });
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () =>
            data?.feed?.stories?.length
                ? data.feed?.stories[data.feed?.stories.length - 1]?.date
                : null,
        [data]
    );
    useEffect(() => {
        logCurrentScreen('HomePage');
    }, [logCurrentScreen])
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.feed?.stories?.length ||
                        fetchMoreResult.feed.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    feed: {
                        ...fetchMoreResult.feed,
                        stories: [...prev.feed.stories, ...fetchMoreResult.feed.stories],
                    },
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    {banner && <HomeBanner banner={data?.feed?.banner as any} />}
                    {headlines && <HomeHeadlines headlines={data?.feed?.headlines as any} />}
                    {coverages && <HomeCoverages coverages={data?.feed?.coverages as any} />}
                    <HomeStories
                        stories={data?.feed?.stories as any}
                        loading={loading}
                        pagination={pagination}
                        lastStoryDate={lastStoryDate}
                        onFetchMore={onFetchMore}
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default HomePage;
