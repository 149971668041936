import moment from "moment";
import React, { useMemo } from "react";
import { IonButton, IonIcon, IonThumbnail, IonLabel, IonText } from "@ionic/react";
import { IStory } from "../types/graphql";
import { ellipsisVertical } from "ionicons/icons";

interface IStoryItemHorizontalPros {
    story: IStory | null;
    onClick: any;
    onClickMenu?: any;
    onClickSource?: any;
}

const StoryItemHorizontal: React.FC<IStoryItemHorizontalPros> = ({ story, onClick, onClickMenu, onClickSource }) => {
    const summary = useMemo<string>(() => {
        const LENGTH = 250;
        return story?.summary && story.summary.length > LENGTH
            ? story.summary.substr(0, LENGTH) + "..."
            : story?.summary || "";
    }, [story]);
    return (
        <div
            style={{
                paddingLeft: 16,
                marginBottom: 40,
                display: "flex",
                flexDirection: "row",
                width: "100%",
            }}
        >
            {story?.image && (
                <IonThumbnail slot="start" style={{ minWidth: 250, height: "100%" }}>
                    <img src={story?.image?.url as string} alt={story?.title as string} />
                </IonThumbnail>
            )}
            <IonLabel className="ion-text-wrap" style={{ marginLeft: 16 }}>
                <div className="link" onClick={onClickSource}>
                    {story?.source?.name}
                </div>
                <IonText>
                    <div className="link" onClick={onClick}>
                        <h2 style={{ fontSize: 22, margin: "8px 0 8px 0" }}>{story?.title}</h2>
                    </div>
                </IonText>
                <p style={{ marginTop: 10 }}>{summary}</p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <IonText>
                        <p style={{ fontSize: 12 }}>{moment(story?.date).format("LLL")}</p>
                    </IonText>
                    <IonButton size="small" slot="end" fill="clear" onClick={onClickMenu}>
                        <IonIcon slot="icon-only" color="medium" icon={ellipsisVertical} />
                    </IonButton>
                </div>
            </IonLabel>
        </div>
    );
};

export default StoryItemHorizontal;
