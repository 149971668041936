import React from "react";
import { IonButton, IonIcon, IonItem, IonThumbnail, IonLabel, IonBadge, IonButtons } from "@ionic/react";
import { ISource } from "../types/graphql";
import { ellipsisVertical, star, starOutline } from "ionicons/icons";

interface ISourceItemPros {
    source: ISource | null;
    included?: boolean;
    favorite?: boolean;
    onClick: any;
    onClickMenu?: any;
    onClickFavorite?: any;
}

const SourceItem: React.FC<ISourceItemPros> = ({ source, included, favorite, onClick, onClickMenu, onClickFavorite }) => {
    return (
        <IonItem
            lines="none"
            style={{ padding: "8px 0 8px 0" }}
            button
            onClick={onClick}
        >
            <IonThumbnail slot="start" style={{ width: 32, height: 32 }}>
                <img src={source?.image?.url as string} alt={source?.name as string} />
            </IonThumbnail>
            <IonLabel>
                {!included && <IonBadge color="medium">Oculta</IonBadge>}
                <h2>{source?.name}</h2>
                {source?.government && <small style={{ color: "grey" }}>*Controlado por el Gobierno Cubano</small>}
            </IonLabel>
            <IonButtons slot="end">
                {
                    onClickFavorite && <IonButton fill="clear" onClick={onClickFavorite}>
                        <IonIcon slot="icon-only" color="medium" icon={favorite ? star : starOutline} />
                    </IonButton>
                }
                {
                    onClickMenu && <IonButton fill="clear" onClick={onClickMenu}>
                        <IonIcon slot="icon-only" color="medium" icon={ellipsisVertical} />
                    </IonButton>
                }
            </IonButtons>

        </IonItem>
    );
};

export default SourceItem;
