import React, { useEffect, useState } from "react";
import { IonItem } from "@ionic/react";
import Geocode from "react-geocode";
import StoryItemContainer from "./story-item-container";
import useStoriesQueryCustom from "../hooks/use-stories-query-custom";
import StoryItemThumbnailSkeleton from "./story-item-thumbnail-skeleton";
import { IStory } from "../types/graphql";

Geocode.setApiKey("AIzaSyBk4bxwq-vDmnHHK1QI_Q0GHHppj_H7Jzo");

Geocode.setLanguage("es");

const PersonalLocality: React.FC = () => {
    const [locality, setLocality] = useState("");
    const { loading, data } = useStoriesQueryCustom({
        skip: !locality,
        variables: { search: locality, limit: 5 },
    });

    useEffect(() => {
        const getLocality = async () => {
            const localityFromIp = await getLocalityFromIp();
            if (localityFromIp) {
                setLocality(localityFromIp);
            } else {
                setLocalityFromBrowser();
            }
        };
        // From ip
        const getLocalityFromIp = async () => {
            try {
                const res = await fetch(
                    "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e5380384257"
                );
                const resJson = await res.json();
                return resJson.city || resJson.state;
            } catch (e) {
                return null;
            }
        };
        // From browser
        const setLocalityFromBrowser = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position: Position) => {
                    const response = await Geocode.fromLatLng(
                        position.coords.latitude.toString(),
                        position.coords.longitude.toString()
                    );
                    const address = response.results.length ? response.results[0] : null;
                    if (address) {
                        const component = address.address_components.find(
                            (address_component: any) => address_component.types.includes("locality")
                        );
                        if (component) {
                            setLocality(component.long_name);
                        }
                    }
                });
            }
        };
        // Get locality
        getLocality();
    }, []);
    if (!locality) {
        return null;
    }
    return (
        <>
            <IonItem>
                <h3>Historias en {locality}</h3>
            </IonItem>
            <div>
                {loading && (
                    <>
                        <StoryItemThumbnailSkeleton />
                        <StoryItemThumbnailSkeleton />
                        <StoryItemThumbnailSkeleton />
                    </>
                )}
                {data?.stories?.map((story) => (
                    <StoryItemContainer key={story?._id} story={story as IStory} thumbnail />
                ))}
            </div>
        </>
    );
};

export default PersonalLocality;
