import React from "react";
import { IonItem, IonSkeletonText, IonThumbnail } from "@ionic/react";

const SourceItemSkeleton: React.FC = () => {
    return (
        <IonItem lines="none" style={{ padding: "8px 0 8px 0" }}>
            <IonThumbnail slot="start" style={{ width: 40, height: 40 }}>
                <IonSkeletonText animated />
            </IonThumbnail>
            <IonSkeletonText animated style={{ width: "80%", height: 32 }} />
        </IonItem>
    );
};

export default SourceItemSkeleton;
