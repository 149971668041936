import React, { useState } from "react";
import {
    IonModal,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonToggle,
    IonButton,
    IonSpinner,
} from "@ionic/react";
import { useSourcesQuery } from "../types/graphql";
import { useUser } from "../providers/user-provider";

const WelcomeModal: React.FC = () => {
    const { loading, data } = useSourcesQuery();
    const { setSources } = useUser();
    const [sources, setSourcesSelected] = useState<string[]>([]);
    return (
        <IonModal
            isOpen={true}
            backdropDismiss={false}
            cssClass="welcome-modal ion-modal-scrollable"
        >
            <div className="ion-padding ion-text-center">
                <h3>
                    Seleccione las fuentes
                    <br /> de información de su preferencia
                </h3>
                <p style={{ color: "#989aa2" }}>
                    No respaldamos, apoyamos, representamos, ni garantizamos; la integridad,
                    veracidad, exactitud o confiabilidad de ningún contenido publicado a través de
                    las fuentes de noticias.
                </p>
            </div>
            {loading ? (
                <div className="ion-text-center">
                    <IonSpinner />
                </div>
            ) : (
                    <div className="ion-padding">
                        {data?.sources?.map((source) => (
                            <IonItem key={source?._id} lines="none">
                                <IonThumbnail slot="start" style={{ width: 32, height: 32 }}>
                                    <img
                                        src={source?.image?.url as string}
                                        alt={source?.name as string}
                                    />
                                </IonThumbnail>
                                <IonLabel>
                                    <h2>{source?.name}</h2>
                                    {source?.government && <small style={{ color: "grey" }}>*Controlado por el Gobierno Cubano</small>}
                                </IonLabel>
                                <IonToggle
                                    checked={sources.includes(source?._id)}
                                    onClick={(e) => {
                                        setSourcesSelected(
                                            !sources.includes(source?._id)
                                                ? [...sources, source?._id]
                                                : sources.filter((item: any) => item !== source?._id)
                                        );
                                    }}
                                />
                            </IonItem>
                        ))}
                        <IonItem lines="none">
                            <IonThumbnail slot="start" style={{ width: 32, height: 32 }} />
                            <IonLabel>Seleccionar Todas</IonLabel>
                            <IonToggle
                                checked={sources.length === data?.sources?.length}
                                onClick={(e) => {
                                    setSourcesSelected(
                                        sources.length !== data?.sources?.length
                                            ? data?.sources?.map((source) => source?._id) || []
                                            : []
                                    );
                                }}
                            />
                        </IonItem>
                    </div>
                )}
            <div className="ion-padding ion-text-end">
                <IonButton
                    disabled={!sources.length}
                    color="dark"
                    fill="outline"
                    onClick={() => setSources(sources)}
                >
                    Siguiente
                </IonButton>
            </div>
        </IonModal>
    );
};

export default WelcomeModal;
