import React from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { IStory } from "../types/graphql";

interface IStoriesSliderProps {
    stories?: IStory[];
    onClick: any;
}

const StoriesSlider: React.FC<IStoriesSliderProps> = ({ stories, onClick }) => {
    if (!stories?.length) {
        return null;
    }
    return (
        <IonSlides
            key={stories.map(story => story._id).join('_')}
            style={{ height: 230 }}
            pager={true}
            options={{
                width: 300,
                autoplay: {
                    delay: 4000
                },
                spaceBetween: 8
            }}>
            {
                stories.filter(story => !!story.image).map((story) => <IonSlide key={story._id} >
                    <div
                        onClick={() => onClick(story)}
                        style={{
                            cursor: "pointer",
                            borderRadius: 8,
                            marginBottom: 30,
                            height: 200,
                            width: '100%',
                            backgroundImage: `url(${story.image?.url})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover"
                        }}>
                        <div style={{
                            position: "fixed",
                            width: 280,
                            bottom: 40,
                            textAlign: "left",
                            paddingLeft: 10
                        }}>
                            <div style={{
                                padding: 4,
                                borderRadius: 4,
                                backgroundColor: "#efefef",
                                fontSize: 16,
                                display: "inline"
                            }}>{story.source?.name}</div>
                            <div style={{
                                padding: 4,
                                borderRadius: 4,
                                marginTop: 8,
                                backgroundColor: "#efefef",
                                fontSize: 14,
                            }}> {story.title}</div>
                        </div>
                    </div>
                </IonSlide>)
            }
        </IonSlides >
    );
};

export default StoriesSlider;
