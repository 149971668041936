import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
}

export interface IQuery {
  readonly __typename?: 'Query';
  readonly story?: Maybe<IStory>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly source?: Maybe<ISource>;
  readonly sources?: Maybe<ReadonlyArray<Maybe<ISource>>>;
  readonly coverages?: Maybe<ReadonlyArray<Maybe<ICoverage>>>;
  readonly feed?: Maybe<IFeed>;
}


export interface IQueryStoryArgs {
  id: Scalars['MongoID'];
}


export interface IQueryStoriesArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
  stories_like?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_dislike?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  section?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['MongoID']>;
  related_text?: Maybe<Scalars['String']>;
}


export interface IQuerySourceArgs {
  id: Scalars['MongoID'];
}


export interface IQuerySourcesArgs {
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}


export interface IQueryCoveragesArgs {
  language?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['Date']>;
  updatedAt_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  pinned?: Maybe<Scalars['Boolean']>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}


export interface IQueryFeedArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  search?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_like?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_dislike?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
}

export interface IStory {
  readonly __typename?: 'Story';
  readonly key: Scalars['String'];
  readonly image?: Maybe<IImage>;
  readonly title: Scalars['String'];
  readonly summary?: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly source?: Maybe<ISource>;
  readonly date?: Maybe<Scalars['Date']>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<ICategory>>>;
  readonly type?: Maybe<EnumStoryType>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly locked?: Maybe<Scalars['Boolean']>;
  readonly section?: Maybe<EnumStorySection>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export interface IImage {
  readonly __typename?: 'Image';
  readonly path: Scalars['String'];
  readonly url: Scalars['String'];
  readonly mime?: Maybe<Scalars['String']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly originalUrl?: Maybe<Scalars['String']>;
  readonly used?: Maybe<Scalars['Boolean']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}



export interface ISource {
  readonly __typename?: 'Source';
  readonly key?: Maybe<Scalars['String']>;
  readonly image?: Maybe<IImage>;
  readonly imageStory?: Maybe<IImage>;
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly interval?: Maybe<EnumSourceInterval>;
  readonly url: Scalars['String'];
  readonly feed: Scalars['String'];
  readonly scraper: Scalars['String'];
  readonly scraperEnabled?: Maybe<Scalars['Boolean']>;
  readonly scraperRegex?: Maybe<Scalars['String']>;
  readonly scraperSlice?: Maybe<Scalars['Float']>;
  readonly scraperSkipMeta?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly section?: Maybe<EnumSourceSection>;
  readonly contact?: Maybe<Scalars['String']>;
  readonly government?: Maybe<Scalars['Boolean']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumSourceInterval {
  HOURLY = 'hourly',
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  SEMIDAILY = 'semiDaily',
  DAILY = 'daily'
}

export enum EnumSourceSection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface ICategory {
  readonly __typename?: 'Category';
  readonly name: Scalars['String'];
  readonly type?: Maybe<EnumCategoryType>;
  readonly similar?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly image?: Maybe<IImage>;
  readonly section?: Maybe<EnumCategorySection>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumCategoryType {
  GENERIC = 'generic',
  PERSON = 'person',
  PLACE = 'place',
  EVENT = 'event'
}

export enum EnumCategorySection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export enum EnumStoryType {
  NEWS = 'news'
}

export enum EnumStorySection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface ICoverage {
  readonly __typename?: 'Coverage';
  readonly name?: Maybe<Scalars['String']>;
  readonly auto?: Maybe<Scalars['Boolean']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly pinned?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly translations?: Maybe<Scalars['JSON']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}


export interface IFeed {
  readonly __typename?: 'Feed';
  readonly banner?: Maybe<IBanner>;
  readonly headlines?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly coverages?: Maybe<ReadonlyArray<Maybe<ICoverage>>>;
}

export interface IBanner {
  readonly __typename?: 'Banner';
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
}

export interface IMutation {
  readonly __typename?: 'Mutation';
  readonly storyInteract?: Maybe<Scalars['Boolean']>;
}


export interface IMutationStoryInteractArgs {
  id: Scalars['MongoID'];
  undo?: Maybe<Scalars['Boolean']>;
  interaction?: Maybe<EnumStoryInteraction>;
}

export enum EnumStoryInteraction {
  LIKE = 'like',
  DISLIKE = 'dislike',
  VIEW = 'view',
  CLICK = 'click'
}

export type IFeedQueryVariables = Exact<{
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  search?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
}>;


export type IFeedQuery = { readonly __typename?: 'Query', readonly feed?: Maybe<{ readonly __typename?: 'Feed', readonly banner?: Maybe<{ readonly __typename?: 'Banner', readonly title?: Maybe<string>, readonly url?: Maybe<string> }>, readonly headlines?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'Story' }
      & IStoryFragment
    )>>>, readonly stories?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'Story' }
      & IStoryFragment
    )>>>, readonly coverages?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'Coverage' }
      & ICoverageFragment
    )>>> }> };

export type ICoverageFragment = { readonly __typename?: 'Coverage', readonly _id: any, readonly name?: Maybe<string>, readonly order?: Maybe<number>, readonly enabled?: Maybe<boolean>, readonly pinned?: Maybe<boolean>, readonly updatedAt?: Maybe<any>, readonly createdAt?: Maybe<any>, readonly stories?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )>>> };

export type IImageFragment = { readonly __typename?: 'Image', readonly _id: any, readonly path: string, readonly url: string, readonly mime?: Maybe<string>, readonly ext?: Maybe<string>, readonly originalUrl?: Maybe<string> };

export type ISourceFragment = { readonly __typename?: 'Source', readonly _id: any, readonly key?: Maybe<string>, readonly name: string, readonly description?: Maybe<string>, readonly interval?: Maybe<EnumSourceInterval>, readonly url: string, readonly feed: string, readonly scraper: string, readonly scraperEnabled?: Maybe<boolean>, readonly scraperRegex?: Maybe<string>, readonly scraperSlice?: Maybe<number>, readonly scraperSkipMeta?: Maybe<boolean>, readonly enabled?: Maybe<boolean>, readonly government?: Maybe<boolean>, readonly image?: Maybe<(
    { readonly __typename?: 'Image' }
    & IImageFragment
  )> };

export type IStoryFragment = { readonly __typename?: 'Story', readonly _id: any, readonly url: string, readonly title: string, readonly summary?: Maybe<string>, readonly type?: Maybe<EnumStoryType>, readonly date?: Maybe<any>, readonly enabled?: Maybe<boolean>, readonly categories?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'Category', readonly _id: any, readonly name: string }>>>, readonly source?: Maybe<{ readonly __typename?: 'Source', readonly _id: any, readonly name: string }>, readonly image?: Maybe<(
    { readonly __typename?: 'Image' }
    & IImageFragment
  )> };

export type ISourceQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type ISourceQuery = { readonly __typename?: 'Query', readonly source?: Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )> };

export type ISourcesQueryVariables = Exact<{
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}>;


export type ISourcesQuery = { readonly __typename?: 'Query', readonly sources?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )>>> };

export type IStoryQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type IStoryQuery = { readonly __typename?: 'Query', readonly story?: Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )> };

export type IStoriesQueryVariables = Exact<{
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  related?: Maybe<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  section?: Maybe<Scalars['String']>;
}>;


export type IStoriesQuery = { readonly __typename?: 'Query', readonly stories?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )>>> };

export const ImageFragmentDoc = gql`
    fragment ImageFragment on Image {
  _id
  path
  url
  mime
  ext
  originalUrl
}
    `;
export const StoryFragmentDoc = gql`
    fragment StoryFragment on Story {
  _id
  url
  title
  summary
  type
  categories {
    _id
    name
  }
  source {
    _id
    name
  }
  image {
    ...ImageFragment
  }
  date
  enabled
}
    ${ImageFragmentDoc}`;
export const CoverageFragmentDoc = gql`
    fragment CoverageFragment on Coverage {
  _id
  name
  stories {
    ...StoryFragment
  }
  order
  enabled
  pinned
  updatedAt
  createdAt
}
    ${StoryFragmentDoc}`;
export const SourceFragmentDoc = gql`
    fragment SourceFragment on Source {
  _id
  key
  name
  description
  interval
  url
  feed
  scraper
  scraperEnabled
  scraperRegex
  scraperSlice
  scraperSkipMeta
  enabled
  image {
    ...ImageFragment
  }
  government
}
    ${ImageFragmentDoc}`;
export const FeedDocument = gql`
    query Feed($ids: [MongoID], $search: String, $date_lt: Date, $date_gt: Date, $sources_in: [String], $sources_nin: [String], $categories_in: [String], $categories_nin: [String]) {
  feed(ids: $ids, search: $search, date_lt: $date_lt, date_gt: $date_gt, sources_in: $sources_in, sources_nin: $sources_nin, categories_in: $categories_in, categories_nin: $categories_nin) {
    banner {
      title
      url
    }
    headlines {
      ...StoryFragment
    }
    stories {
      ...StoryFragment
    }
    coverages {
      ...CoverageFragment
    }
  }
}
    ${StoryFragmentDoc}
${CoverageFragmentDoc}`;

/**
 * __useFeedQuery__
 *
 * To run a query within a React component, call `useFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *      categories_in: // value for 'categories_in'
 *      categories_nin: // value for 'categories_nin'
 *   },
 * });
 */
export function useFeedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFeedQuery, IFeedQueryVariables>) {
        return ApolloReactHooks.useQuery<IFeedQuery, IFeedQueryVariables>(FeedDocument, baseOptions);
      }
export function useFeedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFeedQuery, IFeedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFeedQuery, IFeedQueryVariables>(FeedDocument, baseOptions);
        }
export type FeedQueryHookResult = ReturnType<typeof useFeedQuery>;
export type FeedLazyQueryHookResult = ReturnType<typeof useFeedLazyQuery>;
export type FeedQueryResult = ApolloReactCommon.QueryResult<IFeedQuery, IFeedQueryVariables>;
export const SourceDocument = gql`
    query Source($id: MongoID!) {
  source(id: $id) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useSourceQuery__
 *
 * To run a query within a React component, call `useSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
        return ApolloReactHooks.useQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, baseOptions);
      }
export function useSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, baseOptions);
        }
export type SourceQueryHookResult = ReturnType<typeof useSourceQuery>;
export type SourceLazyQueryHookResult = ReturnType<typeof useSourceLazyQuery>;
export type SourceQueryResult = ApolloReactCommon.QueryResult<ISourceQuery, ISourceQueryVariables>;
export const SourcesDocument = gql`
    query Sources($ignore_enabled: Boolean) {
  sources(ignore_enabled: $ignore_enabled) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useSourcesQuery__
 *
 * To run a query within a React component, call `useSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcesQuery({
 *   variables: {
 *      ignore_enabled: // value for 'ignore_enabled'
 *   },
 * });
 */
export function useSourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, baseOptions);
      }
export function useSourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, baseOptions);
        }
export type SourcesQueryHookResult = ReturnType<typeof useSourcesQuery>;
export type SourcesLazyQueryHookResult = ReturnType<typeof useSourcesLazyQuery>;
export type SourcesQueryResult = ApolloReactCommon.QueryResult<ISourcesQuery, ISourcesQueryVariables>;
export const StoryDocument = gql`
    query Story($id: MongoID!) {
  story(id: $id) {
    ...StoryFragment
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStoryQuery, IStoryQueryVariables>) {
        return ApolloReactHooks.useQuery<IStoryQuery, IStoryQueryVariables>(StoryDocument, baseOptions);
      }
export function useStoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStoryQuery, IStoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStoryQuery, IStoryQueryVariables>(StoryDocument, baseOptions);
        }
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>;
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>;
export type StoryQueryResult = ApolloReactCommon.QueryResult<IStoryQuery, IStoryQueryVariables>;
export const StoriesDocument = gql`
    query Stories($ids: [MongoID], $related: MongoID, $limit: Int, $search: String, $date_lt: Date, $date_gt: Date, $sources_in: [String], $sources_nin: [String], $categories_in: [String], $categories_nin: [String], $section: String) {
  stories(ids: $ids, related: $related, limit: $limit, search: $search, date_lt: $date_lt, date_gt: $date_gt, sources_in: $sources_in, sources_nin: $sources_nin, categories_in: $categories_in, categories_nin: $categories_nin, section: $section) {
    ...StoryFragment
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      related: // value for 'related'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *      categories_in: // value for 'categories_in'
 *      categories_nin: // value for 'categories_nin'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStoriesQuery, IStoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IStoriesQuery, IStoriesQueryVariables>(StoriesDocument, baseOptions);
      }
export function useStoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStoriesQuery, IStoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStoriesQuery, IStoriesQueryVariables>(StoriesDocument, baseOptions);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = ApolloReactCommon.QueryResult<IStoriesQuery, IStoriesQueryVariables>;