import React from "react";
import { IonThumbnail, IonLabel, IonText, IonSkeletonText } from "@ionic/react";

const StoryItemHorizontalSkeleton: React.FC = () => {
    return (
        <div
            style={{
                paddingLeft: 16,
                marginBottom: 40,
                display: "flex",
                flexDirection: "row",
                width: "100%",
            }}
        >
            <IonThumbnail slot="start" style={{ minWidth: 250, height: 150 }}>
                <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="ion-text-wrap" style={{ marginLeft: 16, width: "100%" }}>
                <IonSkeletonText animated style={{ height: 20, width: 200, marginBottom: 15 }} />
                <IonText>
                    <IonSkeletonText
                        animated
                        style={{ height: 24, width: "80%", marginBottom: 15 }}
                    />
                </IonText>
                <IonSkeletonText animated style={{ height: 18, width: "100%" }} />
                <IonSkeletonText animated style={{ height: 18, width: "80%" }} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <IonText>
                        <IonSkeletonText animated style={{ height: 22, width: 200 }} />
                    </IonText>
                </div>
            </IonLabel>
        </div>
    );
};

export default StoryItemHorizontalSkeleton;
