import React from "react";
import { IonSlides, IonSlide, IonSkeletonText } from "@ionic/react";

const StoriesSliderSkeleton: React.FC = () => {
    return (
        <IonSlides style={{ height: 230 }} pager={false} options={{
            width: 300,
            spaceBetween: 8
        }}>
            <IonSlide>
                <div style={{ width: '100%', height: 200, borderRadius: 8, }}>
                    <IonSkeletonText animated />
                </div>
            </IonSlide>
            <IonSlide>
                <div style={{ width: '100%', height: 200, borderRadius: 8, }}>
                    <IonSkeletonText animated />
                </div>
            </IonSlide>
            <IonSlide>
                <div style={{ width: '100%', height: 200, borderRadius: 8, }}>
                    <IonSkeletonText animated />
                </div>
            </IonSlide>
        </IonSlides >
    );
};

export default StoriesSliderSkeleton;
