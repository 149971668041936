import moment from "moment";
import React, { useMemo } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { IStory } from "../types/graphql";
import { ellipsisVertical } from "ionicons/icons";
import { useScreen, Breakpoints } from "../hooks/use-screen";
import StoryItemHorizontal from "./story-item-horizontal";

interface IStoryItemPros {
    story: IStory | null;
    onClick: any;
    onClickMenu?: any;
    onClickSource?: any;
}

const StoryItem: React.FC<IStoryItemPros> = ({ story, onClick, onClickMenu, onClickSource }) => {
    const { breakpoint } = useScreen();
    const summary = useMemo<string>(() => {
        const LENGTH = 150;
        return story?.summary && story.summary.length > LENGTH
            ? story.summary.substr(0, LENGTH) + "..."
            : story?.summary || "";
    }, [story]);
    if (breakpoint !== Breakpoints.xs) {
        return <StoryItemHorizontal story={story} onClick={onClick} onClickMenu={onClickMenu} onClickSource={onClickSource} />;
    }
    return (
        <div style={{ width: 320, margin: "0 auto 40px auto" }}>
            <IonCard>
                {story?.image && (
                    <img src={story?.image?.url as string} alt={story?.title as string} />
                )}
                <IonCardHeader>
                    <IonCardSubtitle>
                        <div className="link" onClick={onClickSource}>
                            {story?.source?.name}
                        </div>
                    </IonCardSubtitle>
                    <IonCardTitle>
                        <div className="link" onClick={onClick}>
                            {story?.title}
                        </div>
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {summary}
                    <IonRow style={{ paddingTop: 32 }}>
                        <IonCol size="auto" className="ion-no-padding ion-align-self-center">
                            <p style={{ fontWeight: "bold" }}>
                                {moment(story?.date).format("LLL")}
                            </p>
                        </IonCol>
                        <IonCol className="ion-no-padding ion-text-end">
                            <IonButton fill="clear" size="small" onClick={onClickMenu}>
                                <IonIcon slot="icon-only" color="medium" icon={ellipsisVertical} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </div>
    );
};

export default StoryItem;
