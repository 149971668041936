import React from "react";
import { IonRippleEffect } from "@ionic/react";

export interface IBanner {
    title: string;
    url: string;
}

interface IHomeBannerProps {
    banner?: IBanner;
}

const HomeBanner: React.FC<IHomeBannerProps> = ({ banner }) => {
    if (!banner) {
        return null;
    }
    return (
        <div className="content-xs">
            <div
                className=" ion-activatable ripple-parent ion-text-center"
                style={{ border: "1px solid #d7d8da", borderRadius: 12 }}
                onClick={() => {
                    window.open(banner.url);
                }}
            >
                <h4 style={{ margin: 16 }}>{banner.title}</h4>
                <IonRippleEffect></IonRippleEffect>
            </div>
        </div>
    );
};

export default HomeBanner;
