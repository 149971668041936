import React from "react";
import { IonList, IonItem, IonLabel, IonIcon, IonMenuToggle } from "@ionic/react";
import {
    personOutline,
    earthOutline,
    gridOutline,
    settingsOutline,
    logoApple,
    logoAndroid,
} from "ionicons/icons";
import { useLocation, useHistory } from "react-router";
import { SECTIONS } from "../utils/sections";

interface IMainMenu {
    setSearchTerm: any;
}

const MainMenu: React.FC<IMainMenu> = ({ setSearchTerm }) => {
    const { pathname } = useLocation();
    const { replace } = useHistory();
    const onNavigate = (path: string) => {
        setSearchTerm("");
        if (pathname !== path) {
            replace(path);
        }
    };
    return (
        <IonMenuToggle autoHide={false}>
            <IonList className="main-menu" lines="none">
                <IonItem
                    className={pathname === "/home" ? "active" : ""}
                    button
                    detail={false}
                    onClick={() => onNavigate("/home")}
                >
                    <IonIcon icon={gridOutline} slot="start" />
                    <IonLabel>Inicio</IonLabel>
                </IonItem>
                <IonItem
                    className={pathname === "/personal" ? "active" : ""}
                    button
                    detail={false}
                    onClick={() => onNavigate("/personal")}
                >
                    <IonIcon icon={personOutline} slot="start" />
                    <IonLabel>Mis historias</IonLabel>
                </IonItem>
                <IonItem
                    className={pathname === "/sources" ? "active" : ""}
                    button
                    detail={false}
                    onClick={() => onNavigate("/sources")}
                >
                    <IonIcon icon={earthOutline} slot="start" />
                    <IonLabel>Fuentes</IonLabel>
                </IonItem>
                <div className="ion-divider" />
                {
                    SECTIONS.map((section) => <IonItem
                        key={section.id}
                        className={pathname === `/section/${section.id}` ? "active" : ""}
                        button
                        detail={false}
                        onClick={() => onNavigate(`/section/${section.id}`)}
                    >
                        <IonIcon icon={section.icon} slot="start" />
                        <IonLabel>{section.title}</IonLabel>
                    </IonItem>)
                }
                <div className="ion-divider" />
                <IonItem
                    className={pathname === "/settings" ? "active" : ""}
                    button
                    detail={false}
                    onClick={() => onNavigate("/settings")}
                >
                    <IonIcon icon={settingsOutline} slot="start" />
                    <IonLabel>Ajustes</IonLabel>
                </IonItem>
                <div className="ion-divider" />
                <IonItem
                    button
                    detail={false}
                    onClick={() => window.open(process.env.REACT_APP_IOS_APP, "_blank")}
                >
                    <IonIcon icon={logoApple} slot="start" />
                    <IonLabel>Disponible en iOS</IonLabel>
                </IonItem>
                <IonItem
                    button
                    detail={false}
                    onClick={() => window.open(process.env.REACT_APP_ANDROID_APP, "_blank")}
                >
                    <IonIcon icon={logoAndroid} slot="start" />
                    <IonLabel>Disponible en Android</IonLabel>
                </IonItem>
                <div className="ion-divider" />
                <IonItem detail={false}>
                    <IonLabel color="medium" className="ion-text-wrap">
                        Tenemos en cuenta tus opiniones y sugerencias para brindar un mejor
                        servicio, escríbenos a{" "}
                        <a href="mailto:hola@cubantropia.com">hola@cubantropia.com</a>
                    </IonLabel>
                </IonItem>
                <IonItem
                    button
                    detail={false}
                    onClick={() => window.open(process.env.REACT_APP_TERMS, "_blank")}
                >
                    <IonLabel color="medium">Términos y Condiciones</IonLabel>
                </IonItem>
                <IonItem
                    button
                    detail={false}
                    onClick={() => window.open(process.env.REACT_APP_PRIVACY, "_blank")}
                >
                    <IonLabel color="medium">Política de Privacidad</IonLabel>
                </IonItem>
            </IonList>
        </IonMenuToggle>
    );
};

export default MainMenu;
