import { IStoriesQueryVariables, useStoriesQuery } from "../types/graphql";
import { useUser } from "../providers/user-provider";

const useStoriesQueryCustom = (params?: { variables?: IStoriesQueryVariables; skip?: boolean }) => {
    const { sources } = useUser();
    const { loading, data, fetchMore, refetch, called } = useStoriesQuery({
        variables: { ...params?.variables, sources_in: [...sources] },
        skip: params?.skip || !sources?.length,
    });
    return {
        called,
        data,
        fetchMore,
        loading,
        refetch,
    };
};

export default useStoriesQueryCustom;
