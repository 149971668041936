import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
    IonRouterOutlet,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import MainMenu from "./components/main-menu";
import HomePage from "./pages/home-page";
import PersonalPage from "./pages/personal-page";
import SourcesPage from "./pages/sources-page";
import SettingsPage from "./pages/settings-page";
import SectionPage from "./pages/section-page";
import { useScreen, Breakpoints } from "./hooks/use-screen";
import SearchPage from "./pages/search-page";
import { MainToolbar } from "./components/main-toolbar";
import SourcePage from "./pages/source-page";
import WelcomeModal from "./components/welcome-modal";
import { useUser } from "./providers/user-provider";
import StoriesRelatedPage from "./pages/stories-related-page";

const getUrlParam = (param: string) => new URLSearchParams(window.location.search).get(param);

export const Routes: React.FC = () => {
    const { breakpoint } = useScreen();
    const { sources } = useUser();
    const sideIsAvailable = [Breakpoints.lg, Breakpoints.xl].includes(breakpoint);
    const [showSide, setShowSide] = useState(true);
    const [searchTerm, setSearchTerm] = useState(getUrlParam("term") ?? "");
    return (
        <IonReactRouter>
            <IonMenu contentId="main" disabled={sideIsAvailable}>
                <IonHeader className="no-borders">
                    <IonToolbar></IonToolbar>
                </IonHeader>
                <IonContent className="menu-content">
                    <MainMenu setSearchTerm={setSearchTerm} />
                </IonContent>
            </IonMenu>
            <IonHeader>
                <MainToolbar
                    showSide={showSide}
                    setShowSide={setShowSide}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </IonHeader>
            <IonContent>
                <IonGrid style={{ height: "100%" }}>
                    <IonRow style={{ height: "100%" }}>
                        {sideIsAvailable && (
                            <IonCol className={`sidebar ${showSide ? "show" : "hide"}`}>
                                <MainMenu setSearchTerm={setSearchTerm} />
                            </IonCol>
                        )}
                        <IonCol>
                            <IonRouterOutlet id="main">
                                <Route exact path="/story/:story/related" component={StoriesRelatedPage} />
                                <Route exact path="/section/:section" component={SectionPage} />
                                <Route exact path="/sources/:source" component={SourcePage} />
                                <Route exact path="/search" component={SearchPage} />
                                <Route exact path="/settings" component={SettingsPage} />
                                <Route exact path="/sources" component={SourcesPage} />
                                <Route exact path="/personal" component={PersonalPage} />
                                <Route exact path="/home" component={HomePage} />
                                <Route render={() => <Redirect to="/home" />} />
                            </IonRouterOutlet>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            {!sources?.length && <WelcomeModal />}
        </IonReactRouter>
    );
};
