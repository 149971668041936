import React, { useEffect } from "react";
import { IonContent, IonPage, IonItem, IonToggle, IonLabel, IonIcon } from "@ionic/react";
import { logoRss, tabletLandscapeOutline, locationOutline, albumsOutline } from "ionicons/icons";
import { useUser } from "../providers/user-provider";
import useAnalytics from "../hooks/use-analytics";

const SettingsPage: React.FC = () => {
    const user = useUser();
    const { logCurrentScreen } = useAnalytics();
    useEffect(() => {
        logCurrentScreen('SettingsPage');
    }, [logCurrentScreen])
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    <IonItem>
                        <h3>Ajustes</h3>
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon icon={tabletLandscapeOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                            <h2>Banner</h2>
                            <p>Mostrar banner con información importante</p>
                        </IonLabel>
                        <IonToggle
                            checked={user.banner}
                            onIonChange={(e) => user.setBanner(e.detail.checked)}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon icon={logoRss} slot="start" />
                        <IonLabel className="ion-text-wrap">
                            <h2>Destacadas</h2>
                            <p>Mostrar historias destacadas</p>
                        </IonLabel>
                        <IonToggle
                            checked={user.headlines}
                            onIonChange={(e) => user.setHeadlines(e.detail.checked)}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon icon={albumsOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                            <h2>Coberturas</h2>
                            <p>Mostrar coberturas de noticias</p>
                        </IonLabel>
                        <IonToggle
                            checked={user.coverages}
                            onIonChange={(e) => user.setCoverages(e.detail.checked)}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonIcon icon={locationOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                            <h2>Locales</h2>
                            <p>Mostrar historias locales, tu ubicación es requerida</p>
                        </IonLabel>
                        <IonToggle
                            checked={user.locality}
                            onIonChange={(e) => user.setLocality(e.detail.checked)}
                        />
                    </IonItem>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SettingsPage;
