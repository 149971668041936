import React from "react";
import {
    IonSkeletonText,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonRow,
    IonCol,
} from "@ionic/react";
import { useScreen, Breakpoints } from "../hooks/use-screen";
import StoryItemHorizontalSkeleton from "./story-item-horizontal-skeleton";

const StoryItemSkeleton: React.FC = () => {
    const { breakpoint } = useScreen();
    if (breakpoint !== Breakpoints.xs) {
        return <StoryItemHorizontalSkeleton />;
    }
    return (
        <div style={{ width: 320, margin: "auto" }}>
            <IonCard>
                <IonSkeletonText animated style={{ height: 200, margin: 0 }} />
                <IonCardHeader>
                    <IonCardSubtitle>
                        <IonSkeletonText animated style={{ height: 22, width: 200 }} />
                    </IonCardSubtitle>
                    <IonCardTitle>
                        <IonSkeletonText animated style={{ height: 32, width: "95%" }} />
                        <IonSkeletonText animated style={{ height: 32, width: "70%" }} />
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonSkeletonText animated style={{ height: 28 }} />
                    <IonSkeletonText animated style={{ height: 28 }} />
                    <IonRow style={{ paddingTop: 32 }}>
                        <IonCol size="auto" className="ion-no-padding ion-align-self-center">
                            <IonSkeletonText animated style={{ height: 22, width: 100 }} />
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </div>
    );
};

export default StoryItemSkeleton;
