import React from "react";
import { IStory, ISource, useStoriesQuery } from "../types/graphql";
import StoriesSlider from "./stories-slider";
import SourceItem from "./source-item";
import { useUser } from "../providers/user-provider";
import useAnalytics from "../hooks/use-analytics";
import { useHistory } from "react-router";
import StoriesSliderSkeleton from "./stories-slider-skeleton";

interface ISourceFavoriteProps {
    source?: ISource
}

const SourceFavorite: React.FC<ISourceFavoriteProps> = ({ source }) => {
    const { push } = useHistory();
    const { logStoryClick, logSourceClick } = useAnalytics();
    const { delSourceFavorite } = useUser();
    const { data } = useStoriesQuery({
        variables: {
            sources_in: [source?._id],
            limit: 5,
        }
    });
    const onClickStory = (story: IStory) => {
        logStoryClick(story);
        window.open(story?.url as string, "_blank");
    }
    const onClickSource = (e: any) => {
        logSourceClick(source as ISource);
        push(`/sources/${source?._id}`);
    }
    const onClickSourceFavorite = (e: any) => {
        e.stopPropagation();
        delSourceFavorite(source?._id);
    }
    return (
        <>
            <SourceItem
                included={true}
                favorite={true}
                source={source as ISource}
                onClick={onClickSource}
                onClickFavorite={onClickSourceFavorite} />
            <div className="ion-padding-horizontal">
                {
                    data?.stories ?
                        <StoriesSlider stories={data?.stories as IStory[]} onClick={onClickStory} /> :
                        <StoriesSliderSkeleton />
                }
            </div>
        </>
    );
};

export default SourceFavorite;
