import React from "react";
import { IonItem } from "@ionic/react";
import StoryItemContainer from "./story-item-container";
import { useUser } from "../providers/user-provider";

const PersonalFavorites: React.FC = () => {
    const { favorites } = useUser();
    return (
        <>
            <IonItem>
                <h3>Favoritas</h3>
            </IonItem>
            <div
                className="ion-padding-top"
                style={{ display: "flex", flexWrap: "wrap", justifyContent: "start" }}
            >
                {favorites?.map((story) => (
                    <StoryItemContainer key={story?._id} story={story} />
                ))}
            </div>
        </>
    );
};

export default PersonalFavorites;
