import { useFirebase } from "../providers/firebase-provider";
import { IStory, ISource } from "../types/graphql";

const useAnalytics = () => {
    const { analytics } = useFirebase();

    const logCurrentScreen = (screenName: string) => {
        analytics.setCurrentScreen(screenName);
    }

    const logStoryClick = (story: IStory) => {
        analytics.logEvent("story_click", {
            "story_id": story._id,
            "story_title": story.title && story.title.length > 100 ? story?.title.substr(0, 100) : story?.title,
            "source_id": story.source?._id,
            "source_name": story.source?.name,
        });
    }

    const logStoryShare = (story: IStory) => {
        analytics.logEvent("story_share", {
            "story_id": story._id,
            "story_title": story.title && story.title.length > 100 ? story?.title.substr(0, 100) : story?.title,
            "source_id": story.source?._id,
            "source_name": story.source?.name,
        });
    }

    const logStorySave = (story: IStory) => {
        analytics.logEvent("story_save", {
            "story_id": story._id,
            "story_title": story.title && story.title.length > 100 ? story?.title.substr(0, 100) : story?.title,
            "source_id": story.source?._id,
            "source_name": story.source?.name,
        });
    }

    const logSourceClick = (source: ISource) => {
        analytics.logEvent("source_click", {
            "source_id": source._id,
            "source_name": source.name,
        });
    }

    const logSourceHide = (source: ISource) => {
        analytics.logEvent("source_hide", {
            "source_id": source._id,
            "source_name": source.name,
        });
    }

    const logSourceFavorite = (source: ISource) => {
        analytics.logEvent("source_favorite", {
            "source_id": source._id,
            "source_name": source.name,
        });
    }

    const logSearch = (search_term: string) => {
        analytics.logEvent("search", { search_term });
    }

    return {
        logCurrentScreen,
        logStoryClick,
        logStoryShare,
        logStorySave,
        logSourceClick,
        logSourceHide,
        logSourceFavorite,
        logSearch
    }

};

export default useAnalytics;