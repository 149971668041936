import React, { createContext, ReactNode, useContext, useState } from "react";

const STORAGE_PREFIX = "CUBANTROPIA";
const SEARCHES_STORAGE_KEY = `${STORAGE_PREFIX}::SEARCHES`;
const FAVORITES_STORAGE_KEY = `${STORAGE_PREFIX}::FAVORITES`;
const SOURCES_STORAGE_KEY = `${STORAGE_PREFIX}::SOURCES`;
const SOURCES_FAVORITES_STORAGE_KEY = `${STORAGE_PREFIX}::SOURCES_FAVORITES`;
const BANNER_STORAGE_KEY = `${STORAGE_PREFIX}::BANNER`;
const HEADLINES_STORAGE_KEY = `${STORAGE_PREFIX}::HEADLINES`;
const LOCALITY_STORAGE_KEY = `${STORAGE_PREFIX}::LOCALITY`;
const COVERAGES_STORAGE_KEY = `${STORAGE_PREFIX}::COVERAGES`;

const get = (key: string): any => {
    const rawValue = localStorage.getItem(key);
    return rawValue ? JSON.parse(rawValue) : null;
};

const set = (key: string, value: any) => {
    return localStorage.setItem(key, JSON.stringify(value));
};

interface IUser {
    searches: string[];
    favorites: any[];
    sources: string[];
    sourcesFavorites: string[];
    banner: boolean;
    headlines: boolean;
    locality: boolean;
    coverages: boolean;
    addSearch: (search: string) => void;
    addFavorite: (story: any) => void;
    delFavorite: (story: any) => void;
    setSources: (story: string[]) => void;
    addSource: (source: string) => void;
    delSource: (source: string) => void;
    addSourceFavorite: (source: string) => void;
    delSourceFavorite: (source: string) => void;
    setBanner: (value: boolean) => void;
    setHeadlines: (value: boolean) => void;
    setLocality: (value: boolean) => void;
    setCoverages: (value: boolean) => void;
}

const UserContext = createContext<IUser>({} as IUser);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [searches, _setSearches] = useState<string[]>(get(SEARCHES_STORAGE_KEY) || []);
    const [favorites, _setFavorites] = useState<string[]>(get(FAVORITES_STORAGE_KEY) || []);
    const [sources, _setSources] = useState<string[]>(get(SOURCES_STORAGE_KEY) || []);
    const [sourcesFavorites, _setSourcesFavorites] = useState<string[]>(get(SOURCES_FAVORITES_STORAGE_KEY) || []);
    const [banner, _setBanner] = useState<boolean>(get(BANNER_STORAGE_KEY) ?? true);
    const [headlines, _setHeadlines] = useState<boolean>(get(HEADLINES_STORAGE_KEY) ?? true);
    const [locality, _setLocality] = useState<boolean>(get(LOCALITY_STORAGE_KEY) ?? true);
    const [coverages, _setCoverages] = useState<boolean>(get(COVERAGES_STORAGE_KEY) ?? true);

    const addSearch = (search: string) => {
        const exists = searches.indexOf(search) > -1;
        if (!exists && search) {
            searches.unshift(search);
            if (searches.length > 5) {
                searches.splice(-1, 1);
            }
            // local
            _setSearches([...searches]);
            // storage
            set(SEARCHES_STORAGE_KEY, searches);
        }
    };

    const addFavorite = (story: any) => {
        const index = favorites.indexOf(story);
        if (index === -1) {
            favorites.push(story);
            // local
            _setFavorites([...favorites]);
            // storage
            set(FAVORITES_STORAGE_KEY, favorites);
        }
    };

    const delFavorite = (story: any) => {
        const index = favorites.indexOf(story);
        if (index > -1) {
            favorites.splice(index, 1);
            // local
            _setFavorites([...favorites]);
            // storage
            set(FAVORITES_STORAGE_KEY, favorites);
        }
    };

    const setSources = (newSources: string[]) => {
        // local
        _setSources([...newSources]);
        // storage
        set(SOURCES_STORAGE_KEY, newSources);
    };

    const addSource = (source: string) => {
        const index = sources.indexOf(source);
        if (index === -1) {
            sources.push(source);
            // local
            _setSources([...sources]);
            // storage
            set(SOURCES_STORAGE_KEY, sources);
        }
    };

    const delSource = (source: string) => {
        const index = sources.indexOf(source);
        if (index > -1) {
            sources.splice(index, 1);
            // local
            _setSources([...sources]);
            // storage
            set(SOURCES_STORAGE_KEY, sources);
        }
    };

    const addSourceFavorite = (source: string) => {
        const index = sourcesFavorites.indexOf(source);
        if (index === -1) {
            sourcesFavorites.unshift(source);
            if (sourcesFavorites.length > 3) {
                sourcesFavorites.pop();
            }
            // local
            _setSourcesFavorites([...sourcesFavorites]);
            // storage
            set(SOURCES_FAVORITES_STORAGE_KEY, sourcesFavorites);
        }
    };

    const delSourceFavorite = (source: string) => {
        const index = sourcesFavorites.indexOf(source);
        if (index > -1) {
            sourcesFavorites.splice(index, 1);
            // local
            _setSourcesFavorites([...sourcesFavorites]);
            // storage
            set(SOURCES_FAVORITES_STORAGE_KEY, sourcesFavorites);
        }
    };

    const setBanner = (value: boolean) => {
        // local
        _setBanner(value);
        // storage
        set(BANNER_STORAGE_KEY, value);
    };

    const setHeadlines = (value: boolean) => {
        // local
        _setHeadlines(value);
        // storage
        set(HEADLINES_STORAGE_KEY, value);
    };

    const setLocality = (value: boolean) => {
        // local
        _setLocality(value);
        // storage
        set(LOCALITY_STORAGE_KEY, value);
    };

    const setCoverages = (value: boolean) => {
        // local
        _setCoverages(value);
        // storage
        set(COVERAGES_STORAGE_KEY, value);
    };

    return (
        <UserContext.Provider
            value={{
                searches,
                favorites,
                sources,
                sourcesFavorites,
                banner,
                headlines,
                locality,
                coverages,
                addSearch,
                addFavorite,
                delFavorite,
                setSources,
                addSource,
                delSource,
                addSourceFavorite,
                delSourceFavorite,
                setBanner,
                setHeadlines,
                setLocality,
                setCoverages
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
