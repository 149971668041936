import React from "react";
import { IonItem } from "@ionic/react";
import { IStory, ICoverage } from "../types/graphql";
import StoriesSlider from "./stories-slider";
import useAnalytics from "../hooks/use-analytics";

interface IHomeCoveragesProps {
    coverages?: ICoverage[];
}

const HomeCoverages: React.FC<IHomeCoveragesProps> = ({ coverages }) => {
    const { logStoryClick } = useAnalytics();
    if (!coverages?.length) {
        return null;
    }

    const onClick = (story: IStory) => {
        logStoryClick(story);
        window.open(story?.url as string, "_blank");
    }

    return (
        <>
            <IonItem>
                <h3>Coberturas</h3>
            </IonItem>
            <div className="ion-padding-start">
                {coverages.map((coverage) => (
                    <div key={coverage._id}>
                        <p>{coverage.name}</p>
                        <StoriesSlider stories={coverage.stories as IStory[]} onClick={onClick} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default HomeCoverages;
