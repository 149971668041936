import React from "react";

import { IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonSearchbar } from "@ionic/react";
import { menuController } from "@ionic/core";
import { menuSharp, search } from "ionicons/icons";
import { useHistory, useLocation } from "react-router";

interface IMainToolbar {
    showSide: boolean;
    setShowSide: any;
    searchTerm: string;
    setSearchTerm: any;
}

export const MainToolbar: React.FC<IMainToolbar> = ({
    showSide,
    setShowSide,
    searchTerm,
    setSearchTerm,
}) => {
    const { pathname } = useLocation();
    const { replace } = useHistory();
    return (
        <IonToolbar>
            <IonButtons slot="start">
                <IonButton
                    fill="clear"
                    onClick={async () => {
                        menuController.toggle();
                        setShowSide(!showSide);
                    }}
                >
                    <IonIcon slot="icon-only" icon={menuSharp} />
                </IonButton>
            </IonButtons>
            <IonTitle>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Cubantropia</div>
                    <div className="ion-hide-lg-down" style={{ width: "100%" }}>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                replace(`/search?term=${searchTerm}`);
                            }}
                        >
                            <IonSearchbar
                                placeholder="Buscar historias"
                                className="main-search"
                                value={searchTerm}
                                debounce={0}
                                onIonChange={(e) => setSearchTerm(e.detail.value!)}
                            />
                        </form>
                    </div>
                </div>
            </IonTitle>
            <IonButtons slot="end">
                <IonButton
                    className="ion-hide-lg-up"
                    fill="clear"
                    onClick={async () => {
                        if (pathname !== "/search") {
                            replace("/search");
                        }
                    }}
                >
                    <IonIcon slot="icon-only" icon={search} />
                </IonButton>
            </IonButtons>
        </IonToolbar>
    );
};
