import React, { useState, useMemo, useEffect } from "react";
import {
    IonContent,
    IonPage,
    IonSpinner,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
    IonSkeletonText,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useStoriesQuery, useSourceQuery, IStory } from "../types/graphql";
import StoryItemSkeleton from "../components/story-item-skeleton";
import StoryItemContainer from "../components/story-item-container";
import useAnalytics from "../hooks/use-analytics";

interface ISourcePageProps
    extends RouteComponentProps<{
        source: string;
    }> { }

const SourcePage: React.FC<ISourcePageProps> = ({ match }) => {
    const { logCurrentScreen } = useAnalytics();
    useEffect(() => {
        logCurrentScreen('SourcePage');
    }, [logCurrentScreen])
    const { data: dataSource, loading: loadingSource } = useSourceQuery({
        variables: { id: match.params.source },
    });
    const source = dataSource?.source;
    const { data, loading, fetchMore } = useStoriesQuery({
        variables: { sources_in: [match.params.source] },
    });
    const stories = data?.stories?.filter((story) => story?.source?._id === match.params.source);
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (stories?.length ? stories[stories.length - 1]?.date : null),
        [stories]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!stories?.length && stories.length > 19,
        [stories]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    <IonGrid style={{ maxWidth: 420, margin: "auto" }}>
                        <IonRow>
                            <IonCol size="12" className="ion-text-center">
                                {loadingSource ? (
                                    <div
                                        style={{
                                            margin: "auto",
                                            borderRadius: 10,
                                            backgroundColor: "#efefef",
                                            width: 72,
                                            height: 72,
                                        }}
                                    />
                                ) : (
                                        <img
                                            style={{ width: 64, borderRadius: 10 }}
                                            src={source?.image?.url || undefined}
                                            alt={source?.name || undefined}
                                        />
                                    )}
                            </IonCol>
                            <IonCol size="12" className="ion-text-center">
                                {loadingSource ? (
                                    <IonSkeletonText
                                        animated
                                        style={{ margin: "auto", height: 24, width: 128 }}
                                    />
                                ) : (
                                        <div className="link" onClick={() => window.open(source?.url as string, "_blank")}>
                                            <h1>{source?.name}</h1>
                                            {source?.government && <small style={{ color: "grey" }}>*Controlado por el Gobierno Cubano</small>}
                                        </div>
                                    )}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                    >
                        {loading && (
                            <>
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                                <StoryItemSkeleton />
                            </>
                        )}
                        {stories?.map((story) => (
                            <StoryItemContainer key={story?._id} story={story as IStory} />
                        ))}
                    </div>
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {lastStoryDate && areMoreStories && !pagination.completed && (
                            <div style={{ textAlign: "center", marginBottom: 64 }}>
                                {pagination.loading ? (
                                    <IonSpinner style={{ height: 40 }} />
                                ) : (
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => onFetchMore()}
                                        >
                                            Más Historias
                                        </IonButton>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage >
    );
};

export default SourcePage;
