import React from "react";
import { IonItem, IonThumbnail, IonLabel, IonText, IonSkeletonText } from "@ionic/react";

const StoryItemThumbnailSkeleton: React.FC = () => {
    return (
        <IonItem lines="none">
            <IonThumbnail slot="start">
                <IonSkeletonText animated style={{ height: 60 }} />
            </IonThumbnail>
            <IonLabel className="ion-text-wrap">
                <IonSkeletonText animated style={{ height: 20, width: 100 }} />
                <IonText>
                    <IonSkeletonText animated style={{ height: 24 }} />
                </IonText>
                <IonText>
                    <IonSkeletonText animated style={{ height: 20, width: 150 }} />
                </IonText>
            </IonLabel>
        </IonItem>
    );
};

export default StoryItemThumbnailSkeleton;
