import React, { useState } from "react";
import { IonPopover, IonList, IonItem, IonIcon } from "@ionic/react";
import { ISource } from "../types/graphql";
import { useUser } from "../providers/user-provider";
import SourceItem from "./source-item";
import { openOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import useAnalytics from "../hooks/use-analytics";

interface ISourceItemContainerPros {
    source: ISource | null;
}

const SourceItemContainer: React.FC<ISourceItemContainerPros> = ({ source }) => {
    const { logSourceClick, logSourceHide } = useAnalytics();
    const { push } = useHistory();
    const { sources, addSource, delSource, sourcesFavorites, addSourceFavorite, delSourceFavorite } = useUser();
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const onClick = () => {
        logSourceClick(source as ISource);
        push(`/sources/${source?._id}`);

    }
    const onClickMenu = (e: any) => {
        e.stopPropagation();
        setShowMenu(e.nativeEvent);
    };
    const onClickFavorite = (e: any) => {
        e.stopPropagation();
        if (sourcesFavorites.includes(source?._id)) {
            delSourceFavorite(source?._id);
        } else {
            addSourceFavorite(source?._id);
        }
    };
    return (
        <>
            <SourceItem
                included={sources.includes(source?._id)}
                favorite={sourcesFavorites.includes(source?._id)}
                source={source}
                onClick={onClick}
                onClickMenu={onClickMenu}
                onClickFavorite={onClickFavorite}
            />
            <IonPopover
                event={showMenu}
                isOpen={!!showMenu}
                onDidDismiss={(e) => setShowMenu(undefined)}
            >
                <IonList lines="none">
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            window.open(source?.url as string, "_blank");
                        }}
                    >
                        <IonIcon icon={openOutline} slot="start" />
                        Ir a {source?.name}
                    </IonItem>
                    {sources.includes(source?._id) ? (
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                logSourceHide(source as ISource);
                                delSource(source?._id);
                            }}
                        >
                            <IonIcon icon={eyeOffOutline} slot="start" />
                            Ocultar historias de {source?.name}
                        </IonItem>
                    ) : (
                            <IonItem
                                button
                                detail={false}
                                onClick={() => {
                                    setShowMenu(undefined);
                                    addSource(source?._id);
                                }}
                            >
                                <IonIcon icon={eyeOutline} slot="start" />
                            Mostrar historias de {source?.name}
                            </IonItem>
                        )}
                </IonList>
            </IonPopover>
        </>
    );
};

export default SourceItemContainer;
