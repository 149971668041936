import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import PersonalLocality from "../components/personal-locality";
import PersonalFavorites from "../components/personal-favorites";
import { useUser } from "../providers/user-provider";
import useAnalytics from "../hooks/use-analytics";

const PersonalPage: React.FC = () => {
    const { locality } = useUser();
    const { logCurrentScreen } = useAnalytics();
    useEffect(() => {
        logCurrentScreen('PersonalPage');
    }, [logCurrentScreen])
    return (
        <IonPage>
            <IonContent>
                <div className="content-md ion-margin">
                    {locality && <PersonalLocality />}
                    <PersonalFavorites />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PersonalPage;
