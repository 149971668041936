import { useState, useEffect } from "react";

export enum Breakpoints {
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl",
}

export interface IScreen {
    breakpoint: Breakpoints;
    isMobile: boolean;
}

const getBreakPoint = (width: Number): Breakpoints => {
    if (width < 576) {
        return Breakpoints.xs;
    }
    if (width < 768) {
        return Breakpoints.sm;
    }
    if (width < 992) {
        return Breakpoints.md;
    }
    if (width < 1200) {
        return Breakpoints.lg;
    }
    return Breakpoints.xl;
};

const getScreen = (): IScreen => {
    const breakpoint = getBreakPoint(window.innerWidth);
    return {
        breakpoint,
        isMobile: breakpoint === Breakpoints.xs || breakpoint === Breakpoints.sm,
    };
};

export const useScreen = () => {
    let [screen, setScreen] = useState<IScreen>(getScreen());

    function handleResize() {
        setScreen(getScreen());
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return screen;
};
